import Vue from 'vue';
import VueRouter from 'vue-router';
// import goTo from "vuetify/es5/services/goto";

Vue.use(VueRouter)

const routes = [
    {
        path: "/investor-section",
        redirect: "/investor-dashboard",
        component: () => import("@/layouts/investor-layout/InvestorLayout.vue"),
        children: [
            {
                path: '/investor-dashboard',
                name: 'InvestorDashboard',
                component: () => import('@/views/investors/InvestorDashboardView.vue')
            },
            {
                path: '/investor-projects',
                name: 'InvestorProjects',
                component: () => import('@/views/investors/InvestorProjectsView.vue')
            },
            {
                path: '/investor-events-project',
                name: 'InvestorEventsProject',
                component: () => import('@/views/investors/InvestorEventsProjectView.vue')
            },
            {
                path: '/investor-events/:id',
                name: 'InvestorEvents',
                component: () => import('@/views/investors/InvestorEventsView.vue')
            },
            {
                path: '/investor-notifications',
                name: 'InvestorNotifications',
                component: ()=> import('@/views/investors/InvestorNotificationsView.vue')
            }
        ]
    },
    {
        path: "/",
        redirect: "/formlogin",
        component: () => import("@/layouts/login-layout/LoginLayout.vue"),
        children: [
            {
                path: "/formlogin",
                name: "FormLogin",
                component: () => import("@/views/authentication/FormLogin.vue"),
            },
            {
                path: "/error",
                name: "Error",
                component: () => import("@/views/authentication/Error.vue"),
            },
        ],
    },
    {
        path: "/admin-page",
        redirect: "/admin-user",
        component: () => import("@/layouts/admin-layout/AdminLayout.vue"),
        children: [
            {
                path: "/admin-user",
                name: "AdminUser",
                component: () => import("@/views/admin/AdminUser.vue"),
            },
            {
                path: "/admin-company",
                name: "CompanyModule",
                component: () => import("@/views/admin/CompanyModule.vue"),
            },
            {
                path: "/admin-contacts",
                name: "ContactsModule",
                component: () => import("@/views/admin/ContactsModule.vue"),
            },
            {
                path: "/admin-preferences",
                name: "PreferencesModule",
                component: () => import("@/views/admin/PreferencesModule.vue"),
            },
            {
                path: "/admin-projects",
                name: "ProjectsModule",
                component: () => import("@/views/admin/ProjectsModule.vue"),
            },
            {
                path: "/admin-investors",
                name: "InvestorsModule",
                component: () => import("@/views/admin/InvestorsModule.vue"),
            },
            {
                path: "/admin-advances",
                name: "AdvancesModule",
                component: () => import("@/views/admin/AdvancesModule.vue"),
            },
            {
                path: "/admin-events",
                name: "EventModule",
                component: () => import("@/views/admin/EventModule.vue"),
            },
            {
                path: "/admin-categories",
                name: "CategoriesModule",
                component: () => import("@/views/admin/CategoriesModule.vue"),
            },
            {
                path: "/admin-notifications",
                name: "NotificationModuleAdmin",
                component: ()=> import("@/views/admin/NotificationsModule.vue"),
            }
        ],
    },
    {
        path: '/under-construction',
        name: 'UnderConstruction',
        component: () => import('@/views/UnderConstruction.vue')
    }
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    // This is for the scroll top when click on any router link
    scrollBehavior: (to/*, from, savedPosition*/) => {
        if (to.hash) {
            return window.scrollTo({top: document.querySelector(to.hash).offsetTop, behavior: 'smooth'});
        } else {
            return window.scrollTo({top: 0, behavior: 'smooth'});
        }
    },
    // This is for the scroll top when click on any router link
    routes
})

export default router
