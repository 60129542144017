import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue';
// import Vuetify from 'vuetify/lib';
import Vuetify from 'vuetify/lib/framework';

// import plugin
import { TiptapVuetifyPlugin } from 'tiptap-vuetify';
// don't forget to import CSS styles
import 'tiptap-vuetify/dist/main.css';
// Vuetify's CSS styles
// import 'vuetify/dist/vuetify.min.css';

// import es from './es'

// Vuetify Object (as described in the Vuetify 2 documentation)
const vuetify = new Vuetify({
    lang: {
        current: 'es' // en | es | fr | pl | ru | ptbr
    }
});

Vue.use(Vuetify);

// use this package's plugin
Vue.use(TiptapVuetifyPlugin, {
    // the next line is important! You need to provide the Vuetify Object to this place.
    vuetify, // same as "vuetify: vuetify"
    // optional, default to 'md' (default vuetify icons before v2.0.0)
    iconsGroup: 'mdi'
});


const themeDark = {
    primary: "#398bf7", // change header color from here || "#1e88e6", "#21c1d6", "#fc4b6c", "#563dea", "#9C27b0", "#ff9800"
    info: "#1e88e5",
    success: "#06d79c",
    accent: "#ef5350",
    default: "#563dea",
    darkbarcolor: "#191919"
};

const themeLight = {
    primary: '#ded06f',
    // primary: '#ccac52',
    secondary: '#23326e',
    // secondary: '#171b34',
    accent: '#3D87E4',
    error: '#FF5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FFC107'
};

export default new Vuetify({
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: themeLight,
            dark: themeDark,
        },
        dark: false
    },
    icons: {
        iconfont: 'mdi',
    },
});