<template>
    <v-app id="idoo-app">
        <router-view></router-view>
    </v-app>
</template>

<script>
    import "animate.css";
    import '@/assets/stylesheets/style.css';
    import 'normalize.css';
    import "@fontsource/raleway";

    export default {
        name: 'App',
        components: {},
        created() {
            //Read the status information in the session storage when the page is loaded
            if (sessionStorage.getItem('store')) {
                this.$store.replaceState(
                    Object.assign({},
                        this.$store.state, JSON.parse(sessionStorage.getItem('store'))));
            }
            //Save the information in vuex to sessionstorage when the page is refreshed
            window.addEventListener('beforeunload', () => {
                sessionStorage.setItem('store', JSON.stringify(this.$store.state));
            });
        }
    };
</script>

<style lang="css">
    body {
        font-family: "Raleway",sans-serif;
    }
</style>
